import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import Button from '../components/button'
import CydenIcon from '../components/icons/CydenIcon'
import Input from '../components/input'
import { useAuth } from '../hooks/useAuth'
import { LoginInput } from '../api/types'
import { omitObjectKey } from '../utils/omitObjectKey'
import { Link } from 'react-router-dom'
const ADMIN_URL = import.meta.env.VITE_ADMIN_URL ?? 'http://localhost:8005'

yup.setLocale({
  mixed: {
    default: 'Campo no válido',
    notType: 'Campo no cumple con el tipo de dato',
    required: 'Campo requerido'
  },
  string: {
    email: 'Correo electrónico no válido'
  }
})

const schema = yup.object({
  email: yup.string().email().required(),
  password: yup.string().required()
})

function Login() {
  const form = useForm<LoginInput>({ resolver: yupResolver(schema) })

  const { loginUser } = useAuth()

  const onSubmit = async (data: LoginInput) => {
    loginUser({ email: data.email, password: data.password })
  }

  return (
    <div className="bg-gradient-to-t from-primary to-secondary h-screen flex justify-center items-center flex-col">
      <CydenIcon className="h-24 w-auto transform -rotate-12 md:-mt-12 m-8" />
      <div className="container max-w-md xl:max-w-3xl max-sm:h-full flex bg-white rounded-lg shadow overflow-hidden">
        <div className="w-full p-8">
          <form onSubmit={form.handleSubmit(onSubmit)}>
            <h1 className=" text-2xl font-bold dark:text-black">
              Inicia sesión en tu cuenta
            </h1>
            <div className="mb-4 mt-6">
              <Input
                label="Correo"
                type="email"
                {...omitObjectKey(form.register('email'), 'ref')}
                innerRef={form.register('email').ref}
                error={form.formState.errors.email?.message}
              />
            </div>
            <div className="mb-6 mt-6">
              <Input
                label="Contraseña"
                type="password"
                {...omitObjectKey(form.register('password'), 'ref')}
                innerRef={form.register('password').ref}
                error={form.formState.errors.password?.message}
              />
            </div>
            <div className="flex w-full mt-8 gap-2 justify-between">
              <Link
                to={`${ADMIN_URL}/clientes/reestablecer-clave`}
                className="bg-indigo-300 text-white rounded-md p-2 text-sm"
              >
                Restablecer contraseña
              </Link>
              <Button label="Iniciar sesión" type="submit" />
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default Login
