import { useMutation } from '@tanstack/react-query'
import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import api from '../api'
import { loginUserFn } from '../api/auth'
import { LoginInput } from '../api/types'
import useStore from '../store'

export const useAuth = () => {
  const store = useStore()
  const addToastMessage = useStore((state) => state.addMessage)
  const navigate = useNavigate()

  const logout = useCallback(() => {
    useStore.setState({ userCredentials: { token: '' } })
    api.defaults.headers.Authorization = ''
    navigate('/login')
  }, [navigate])

  const { mutate: loginUser } = useMutation(
    (userData: LoginInput) => loginUserFn(userData),
    {
      onMutate() {
        store.setRequestLoading(true)
      },
      onSuccess: (data) => {
        store.setCredentials({ token: data.access_token })
        api.defaults.headers.Authorization = `Bearer ${data.access_token}`
        store.setRequestLoading(false)
        navigate('/dashboard')
        addToastMessage({
          type: 'success',
          text: 'Bienvenido',
          id: Math.random().toString(36).substring(2, 9)
        })
      },
      onError: (error: Error & { response: { status: number } }) => {
        if (error.response.status === 401) {
          addToastMessage({
            type: 'error',
            text: 'Correo o contraseña incorrectos',
            id: Math.random().toString(36).substring(2, 9)
          })
        } else if (error.message) {
          addToastMessage({
            type: 'error',
            text: error.message,
            id: Math.random().toString(36).substring(2, 9)
          })
        } else {
          addToastMessage({
            type: 'error',
            text: 'Error desconocido ocurrido al iniciar sesión',
            id: Math.random().toString(36).substring(2, 9)
          })
        }
        store.setRequestLoading(false)
        store.setCredentials({ token: '' })
      }
    }
  )

  return {
    user: store.authUser,
    userCredentials: store.userCredentials,
    loginUser,
    logout
  }
}
